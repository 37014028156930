<template>
  <div>
    <div class="mx-2">
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_procedureevidence'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm btn-add"
            @click="$bvModal.show(`new-procedure-evidence-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "manual.evidencia_de_desempeño",
                false,
                "Evidencia de Desempeño"
              )
            }}
          </button>
        </div>
      </div>
      <h3 class="title-header noprint">
        {{
          $getVisibleNames(
            "manual.evidencia_de_desempeño",
            true,
            "Evidencias de Desempeño"
          )
        }}
        <AlternativeNameButton
          :table_name="'manual.evidencia_de_desempeño'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <GenericBTable
        :items="procedure_evidences"
        :filterCustom="filterCustom"
        @emitChangeSearchField="changeInputSearch"
        :fields="procedureEvidenceFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="'procedure_evidences'"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            v-can="'mesh.change_procedureevidence'"
            @click="
              $bvModal.show(`edit-procedure-evidence-modal-${row.item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              'Editar Evidencia de Desempeño'
            "
          ></button-edit>
          <button-delete
            v-can="'mesh.delete_procedureevidence'"
            @click="askForDeleteProcedureEvidence(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              'Eliminar Evidencia de Desempeño'
            "
          ></button-delete>
          <b-modal
            :id="`edit-procedure-evidence-modal-${row.item.id}`"
            title="Editar Evidencia de Desempeño"
            size="md"
            hide-footer
          >
            <ProcedureEvidenceForm
              :ProcedureEvidence="row.item"
              @updated="slotUpdatedProcedureEvidence"
              :institution_id="row.item.school"
              :show_title="false"
            ></ProcedureEvidenceForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-procedure-evidence-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'manual.evidencia_de_desempeño',
        false,
        'Evidencia de Desempeño'
      )}`"
      size="lg"
    >
      <ProcedureEvidenceForm
        @created="slotCreatedProcedureEvidence"
        :institution_id="this.user.school"
      ></ProcedureEvidenceForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "ProcedureEvidenceView",
  components: {
    ProcedureEvidenceForm: () => import("./ProcedureEvidenceForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      procedure_evidences: names.PROCEDURE_EVIDENCES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_procedureevidence");
      if (has_permission) return has_permission;
      else return false;
    },
    procedureEvidenceFields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "manual.evidencia_de_desempeño",
              true,
              "Evidencias de Desempeño"
            )
          ),
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.description, this.input_search)
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    slotCreatedProcedureEvidence() {
      this.$bvModal.hide("new-procedure-evidence-modal");
    },
    slotUpdatedProcedureEvidence(procedure_evidence) {
      this.$bvModal.hide(
        `edit-procedure-evidence-modal-${procedure_evidence.id}`
      );
    },
    askForDeleteProcedureEvidence(procedure_evidence_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Evidencia de Desempeño?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_PROCEDURE_EVIDENCE, procedure_evidence_id)
            .then(() => {
              toast("Evidencia de Desempeño eliminada.");
            });
        }
      });
    },
  },
  created() {
    this.sortBy = "name";
    if (this.user) {
      this.$store.dispatch(names.FETCH_PROCEDURE_EVIDENCES, this.user.school);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
.title-header {
  padding: 0 2rem;
}
.btn-add {
  margin-left: 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  width: 255px;
}
</style>